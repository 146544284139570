import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { DatePipe } from "@angular/common";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NbThemeModule,
  NbLayoutModule,
  NbStepperModule,
  NbInputModule,
  NbDialogModule,
  NbButtonModule,
  NbToastrModule,
  NbFormFieldComponent,
  NbFormFieldModule,
  NbIconModule,
  NbDatepickerModule,
  NbSelectModule,
} from "@nebular/theme";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { AppRoutingModule } from "./app-routing.module";
import { ImageCropDialogComponent } from "./dialogs/image-crop-dialog/image-crop-dialog.component";
import { NgxFileDropModule } from "ngx-file-drop";
import { ImageCropperModule } from "ngx-image-cropper";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { ProposalGeneratorPageComponent } from "./pages/proposal-generator-page/proposal-generator-page.component";
import { StuiStepperComponent } from "./widgets/stui-stepper/stui-stepper.component";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { EditorModule } from "@tinymce/tinymce-angular";
import { StuiDocumentComponent } from "./widgets/stui-document/stui-document.component";

@NgModule({
  declarations: [
    AppComponent,
    ImageCropDialogComponent,
    StuiStepperComponent,
    StuiDocumentComponent,
    HomePageComponent,
    ProposalGeneratorPageComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NbFormFieldModule,
    NbThemeModule.forRoot({ name: "default" }),
    NbDialogModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbLayoutModule,
    NbIconModule,
    NbEvaIconsModule,
    AppRoutingModule,
    NbInputModule,
    NbButtonModule,
    NbStepperModule,
    NgxFileDropModule,
    ImageCropperModule,
    EditorModule,
    CdkStepperModule,
    NbSelectModule,
  ],
  entryComponents: [ImageCropDialogComponent],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule { }
