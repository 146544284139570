export enum proposalTemplate {
  standard = "standard",
  contract = "contract",
  priceList = "priceList",
}

export interface proposal {
  business: string;
  businessName: string;
  businessSurname: string;
  businessOffice: string;
  businessVat: string;
  businessEmail: string;
  businessPhone: string;
  customerPrincipal: string;
  customerName: string;
  customerSurname: string;
  customerVat: string;
  customerPhone: string;
  customerEmail: string;
  customerOffice: string;
  title: string;
  date: any;
  location: string;
  description: string;
  details?: detail[];
  priceList?: priceListRow[];
  contractParagraphs?: contractParagraph[];
}

export interface detail {
  title: string;
  unitCost: number;
  unitPrice: number;
  tax: number;
  discount: number;
  quantity: number;
}

export interface contractParagraph {
  title: string;
  description: string;
}

export interface proposalDetailRow {
  title: string;
  unitPrice: number;
  tax: number;
  discount: number;
  quantity: number;
}

export interface priceListRow {
  title: string;
  description: string;
  unitPrice: number;
}

export interface documentDetailsTotals {
  taxTotal: number;
  proposalTotal: number;
  discountTotal: number;
  total: number;
  taxable: number;
}

