import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { FileSystemFileEntry } from 'ngx-file-drop';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop-dialog',
  templateUrl: './image-crop-dialog.component.html',
  styleUrls: ['./image-crop-dialog.component.scss']
})
export class ImageCropDialogComponent implements OnInit {

  public file: File;
  public croppedImageBase64: string;

  constructor(
    private dialogRef: NbDialogRef<ImageCropDialogComponent>,
    private toastrService: NbToastrService) { }

  ngOnInit() {
  }

  fileDropped(droppedFile) {
    // If "droppedFile" is a file
    if (droppedFile[0].fileEntry.isFile) {
      const fileEntry = droppedFile[0].fileEntry as FileSystemFileEntry;
      fileEntry.file((file: File) => {

        // If the file is an image then continue else
        // notify the user
        if (file['type'].split('/')[0] === 'image') {
          this.file = file;
        } else {
          this.toastrService.show("The file format is invalid", "Attention", { status: 'warning' });
          (<any>document.getElementById("uploadImg")).value = "";
          return
        }

      });
    }
  }



  /**
   * On crop function
   * 
   * @param event image cropped event
   */
  imageCropped(event: ImageCroppedEvent) {
    function urltoFile(url, filename, mimeType) {
      return (fetch(url)
        .then(function (res) { return res.arrayBuffer(); })
        .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
      );
    }

    this.croppedImageBase64 = event.base64;

    //urltoFile(event.base64, "image" + (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)).toString() + ".png", "image/png")
    //  .then((file) => { this.croppedImage = file });
  }

  saveImage() {
    this.dialogRef.close({ file: this.croppedImageBase64 });
  }

  deleteImage() {
    this.dialogRef.close({ delete: true });
  }

  closeDialog() {
    this.dialogRef.close(null);
  }
}
