import { Directionality } from '@angular/cdk/bidi';
import { ChangeDetectorRef, Component, ViewEncapsulation, ViewChildren, QueryList, ElementRef, Output, EventEmitter } from '@angular/core';
import { CdkStepper, CdkStep } from '@angular/cdk/stepper';

@Component({
  selector: 'stui-stepper',
  templateUrl: './stui-stepper.component.html',
  styleUrls: ['./stui-stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: CdkStepper, useExisting: StuiStepperComponent }]
})
export class StuiStepperComponent extends CdkStepper {

  @Output("stepChange") onStepChange: EventEmitter<number> = new EventEmitter<number>();

  constructor(
    private dir: Directionality,
    private changeDetectorRef: ChangeDetectorRef) {
    super(dir, changeDetectorRef);
  }

  /**
   * Triggered when a stui-stepper-step
   * is clicked. Sets the selectedIndex
   * property of CdkStepper
   * 
   * @param index the index of the step clicked
   */
  public onStepSelect(index: number): void {
    this.setStep(index);
  }

  /**
   * Opens the step given
   * at a certain index
   * 
   * @param index the index of the step to set
   */
  private setStep(index: number): void {
    this.selectedIndex = index;
    this.onStepChange.emit(index);
  }

  /**
   * Sets the focus on the
   * first step with an error
   */
  public setFirstErrorStep(): void {
    // Index of the current step
    let idx = 0;

    // Used to focus just the first 
    // step with errors
    let firstFocussed: boolean = false;

    for (let step of this.steps) {
      // Set all the steps as interacted
      step.interacted = true;

      // At the first error found
      // open that step and break
      if (step.hasError && !firstFocussed) {
        this.setStep(idx);
        firstFocussed = true;
      }
      idx++;
    }
  }

  public stepBack(): void {
    if (this.selectedIndex > 0)
      this.setStep(this.selectedIndex - 1);
  }

  public stepForward(): void {
    if (this.selectedIndex < this.steps.length - 1)
      this.setStep(this.selectedIndex + 1);
  }
}
