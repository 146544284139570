import { Component, OnInit, Input, ViewChild } from "@angular/core";

@Component({
  selector: "app-stui-document",
  templateUrl: "./stui-document.component.html",
  styleUrls: ["./stui-document.component.scss"],
})
export class StuiDocumentComponent implements OnInit {
  @Input("doc") doc: string;
  @Input("logoBase64") logoBase64: string;

  constructor() { }

  ngOnInit() {
  }

}
