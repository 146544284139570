import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ImageCropDialogComponent } from "../../dialogs/image-crop-dialog/image-crop-dialog.component";
import {
  proposalTemplate,
  proposalDetailRow,
  contractParagraph,
  priceListRow,
  proposal,
  detail,
  documentDetailsTotals,
} from "../../interfaces";
import { Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { formatCurrency, getCurrencySymbol } from "@angular/common";
import { DatePipe } from "@angular/common";

export interface currency {
  cc: string;
  symbol: string;
  name: string;
}

export const pdfStyle: string =
  "*{ font-family: 'Open Sans', sans-serif !important; color:#222b45;} table{margin-bottom:20px !important;} table.mce-grid-table{border:none !important;} table.mce-grid-table tbody tr td{padding:2px !important;}  table.mce-grid-table[data-mce-selected=\"1\"] tbody tr td   {border: 2px dashed #bbb !important;line-height:22px;} table.satiurn-table{border:none !important;} table.satiurn-table th{border:none !important; border-bottom:solid 1px #8f9bb3 !important;} table.satiurn-table td{font-size:10px !important;padding:5px;} table.satiurn-tab  le tr:nth-child(odd) td{background-color:#fff !important;border:none !important;} table.satiurn-table tr:nth-child(even) td{background-color:#f6f9fc !important;border:none !important;}";

@Component({
  selector: "app-proposal-generator-page",
  templateUrl: "./proposal-generator-page.component.html",
  styleUrls: ["./proposal-generator-page.component.scss"],
})
export class ProposalGeneratorPageComponent {
  readonly configTiny: any = {
    menubar: false,
    skin_url: "https://satiurnapp.b-cdn.net/satiurn-app/satiurn-tinymce-theme/",
    content_css:
      "https://satiurnapp.b-cdn.net/satiurn-app/satiurn-tinymce-content-theme/content.css",
    icons_url: "https://satiurnapp.b-cdn.net/satiurn-app/icons.js",
    icons: "grid-icons",
    content_style: pdfStyle,
    toolbar_mode: "wrap",
    toolbar_sticky: true,
    branding: false,
    resize: false,
    plugins: [
      "autoresize code print preview paste importcss searchreplace autolink directionality code visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
    ],
    toolbar1:
      "undo redo print code | bold italic underline strikethrough | fontselect fontsizeselect formatselect | charmap emoticons | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | insertfile image link | DoubleGrid DoubleSxGrid DoubleDxGrid TripleGrid Logo",
    //toolbar: " | ",
    setup: (editor) => {
      editor.ui.registry.addButton("DoubleGrid", {
        icon: "double_column",
        tooltip: "Insert double grid",
        onAction: (_) => {
          editor.insertContent(
            '<table class="mce-grid-table" style="border-color:transparent; width: 100%;table-layout:fixed;" border="0" data-mce-id="__mce"><tbody><tr><td class="mce-grid-cell" style="border:none">&nbsp;</td><td class="mce-grid-cell" style="border:none">&nbsp;</td></tr></tbody></table>'
          );
        },
      });

      editor.ui.registry.addButton("DoubleSxGrid", {
        icon: "double_lx_column",
        tooltip: "Insert double grid",
        onAction: (_) => {
          editor.insertContent(
            '<table class="mce-grid-table" style="border-color:transparent; width: 100%;table-layout:fixed;" border="0" data-mce-id="__mce"><tbody><tr><td colspan="2" class="mce-grid-cell" style="border:none">&nbsp;</td><td colspan="4" class="mce-grid-cell" style="border:none">&nbsp;</td></tr></tbody></table>'
          );
        },
      });

      editor.ui.registry.addButton("DoubleDxGrid", {
        icon: "double_rx_column",
        tooltip: "Insert double grid",
        onAction: (_) => {
          editor.insertContent(
            '<table class="mce-grid-table" style="border-color:transparent; width: 100%;table-layout:fixed;" border="0" data-mce-id="__mce"><tbody><tr><td colspan="4" class="mce-grid-cell" style="border:none">&nbsp;</td><td colspan="2" class="mce-grid-cell" style="border:none">&nbsp;</td></tr></tbody></table>'
          );
        },
      });

      editor.ui.registry.addButton("TripleGrid", {
        icon: "triple_column",
        tooltip: "Insert triple grid",
        onAction: (_) => {
          editor.insertContent(
            '<table class="mce-grid-table" style="border-color:transparent; width: 100%;table-layout:fixed;" border="0" data-mce-id="__mce"><tbody><tr><td class="mce-grid-cell" style="border:none">&nbsp;</td><td class="mce-grid-cell" style="border:none">&nbsp;</td><td class="mce-grid-cell" style="border:none">&nbsp;</td></tr></tbody></table>'
          );
        },
      });

      editor.ui.registry.addButton("Logo", {
        icon: "insert_logo",
        tooltip: "Insert your business logo",
        onAction: (_) => {
          editor.insertContent(
            '<img src="' +
            this.logoBase64 +
            '" alt="" width="158" height="158" />'
          );
        },
      });
    },
    quickbars_insert_toolbar:
      "quicktable image | DoubleGrid DoubleSxGrid DoubleDxGrid TripleGrid",
    quickbars_selection_toolbar:
      "bold italic underline | formatselect fontsizeselect | quicklink",
    contextmenu: "undo redo | inserttable | cell row column deletetable | help",
  };

  readonly editorStep = 2;
  public currentStep = 0;
  public proposalForm: FormGroup;
  public currentTemplate: proposalTemplate = proposalTemplate.standard;
  public templates = proposalTemplate;
  public doc = "";
  public logoBase64: string;
  public selectedCurrency: currency;

  public proposal: proposal;

  constructor(
    private dialogService: NbDialogService,
    private fb: FormBuilder,
    private http: HttpClient,
    public datepipe: DatePipe,
    private cdRef: ChangeDetectorRef
  ) {
    this.proposal = {
      business: "",
      businessName: "",
      businessSurname: "",
      businessOffice: "",
      businessVat: "",
      businessEmail: "",
      businessPhone: "",
      customerPrincipal: "",
      customerName: "",
      customerSurname: "",
      customerVat: "",
      customerPhone: "",
      customerEmail: "",
      customerOffice: "",
      title: "",
      date: "",
      location: "",
      description: "",
      details: [],
      priceList: [],
      contractParagraphs: [],
    };

    this.addDetail();
    this.addParagraph();
    this.addPriceListRow();

    this.proposalForm = fb.group({
      business: ["", []],
      businessName: ["", []],
      businessSurname: ["", []],
      businessOffice: ["", []],
      businessVat: ["", []],
      businessEmail: ["", []],
      businessPhone: ["", []],
      customerPrincipal: ["", []],
      customerName: ["", []],
      customerSurname: ["", []],
      customerVat: ["", []],
      customerPhone: ["", []],
      customerEmail: ["", []],
      customerOffice: ["", []],
      title: ["", []],
      date: ["", []],
      location: ["", []],
      description: ["", []],
    });
  }

  ngAfterViewInit() { }

  /**
   * Save the data into the proposal object
   */
  public saveProposalData(): void {
    // Set the proposal object
    this.proposal = {
      business: this.f.business.value ? this.f.business.value : "",
      businessName: this.f.businessName.value ? this.f.businessName.value : "",
      businessSurname: this.f.businessSurname.value
        ? this.f.businessSurname.value
        : "",
      businessOffice: this.f.businessOffice.value
        ? this.f.businessOffice.value
        : "",
      businessVat: this.f.businessVat.value ? this.f.businessVat.value : "",
      businessEmail: this.f.businessEmail.value
        ? this.f.businessEmail.value
        : "",
      businessPhone: this.f.businessPhone.value
        ? this.f.businessPhone.value
        : "",
      customerPrincipal: this.f.customerPrincipal.value
        ? this.f.customerPrincipal.value
        : "",
      customerName: this.f.customerName.value ? this.f.customerName.value : "",
      customerSurname: this.f.customerSurname.value
        ? this.f.customerSurname.value
        : "",
      customerVat: this.f.customerVat.value ? this.f.customerVat.value : "",
      customerPhone: this.f.customerPhone.value
        ? this.f.customerPhone.value
        : "",
      customerEmail: this.f.customerEmail.value
        ? this.f.customerEmail.value
        : "",
      customerOffice: this.f.customerOffice.value
        ? this.f.customerOffice.value
        : "",
      title: this.f.title.value ? this.f.title.value : "",
      date: this.f.date.value ? this.f.date.value : "",
      location: this.f.location.value ? this.f.location.value : "",
      description: this.f.description.value ? this.f.description.value.split("\n").join("<br/>") : "",
      details: this.proposal.details,
      priceList: this.proposal.priceList,
      contractParagraphs: this.proposal.contractParagraphs,
    };

    console.log(this.proposal.date);
  }

  public addDetail(): void {
    if (!this.proposal.details) this.proposal.details = [];

    this.proposal.details.push({
      title: "",
      unitCost: 0,
      unitPrice: 0,
      tax: 0,
      discount: 0,
      quantity: 1,
    });
  }

  public addParagraph(): void {
    if (!this.proposal.contractParagraphs)
      this.proposal.contractParagraphs = [];

    this.proposal.contractParagraphs.push({
      title: "",
      description: "",
    });
  }

  public addPriceListRow(): void {
    if (!this.proposal.priceList) this.proposal.priceList = [];

    this.proposal.priceList.push({
      title: "",
      description: "",
      unitPrice: 0,
    });
  }

  public removeDetail(index: number): void {
    this.proposal.details.splice(index, 1);
  }
  public removeParagraph(index: number): void {
    this.proposal.contractParagraphs.splice(index, 1);
  }
  public removePriceListRow(index: number): void {
    this.proposal.priceList.splice(index, 1);
  }

  public selectTemplate(template: proposalTemplate): void {
    this.currentTemplate = template;
  }

  public stepChanged(stepIndex: number): void {
    setTimeout(() => {
      this.currentStep = stepIndex;

      if (this.currentStep == this.editorStep) {
        this.doc = this.proposalTopHtml() + this.proposalBottomHtml();

        console.log(this.proposalBottomHtml());
      }
    }, 0);
  }

  public openLogoDialog(): void {
    this.dialogService
      .open(ImageCropDialogComponent)
      .onClose.subscribe((data) => {
        if (data && data.file) {
          this.logoBase64 = data.file;
        }
      });
  }

  // Convenience
  get f() {
    return this.proposalForm.controls;
  }

  /**
   * Trigger the export PDF from frontend
   */
  public exportPDF(redirect: boolean = false): void {
    const html = this.doc;
    const document =
      "<head>" +
      fontImport +
      "</head><body>" +
      html +
      "<style>body{zoom:75%;padding:25px;} " +
      pdfStyle +
      "</style></body>";
    this.exportHtmlToPdf(document).subscribe(
      (response) => {
        const proposalTitle: string = (this.proposal.title && this.proposal.title.length > 0) ? this.proposal.title : "Proposal";
        this.generatePDF(response, proposalTitle);
      },
      (error) => {
        // TODO error
        console.log(error);
      }
    );

    // Opens satiurn's landing page on new tab
    if (redirect) {
      window.open("https://satiurn.com", "_blank");
    }
  }

  // Utility for the export pdf
  private generatePDF = (response, title) => {
    var blob = new Blob([response], { type: "text/pdf" });

    let a = document.createElement("a");
    var blobUrl = URL.createObjectURL(blob);

    a.href = blobUrl;
    a.download = title + ".pdf";
    a.click();
  };

  // Utility for export pdf
  private businessFPDF = (bool: boolean) => {
    let headers = new HttpHeaders({
      business: "" + bool,
      "Content-Type": "application/json",
    });
    let options = { headers: headers, responseType: "blob" as "json" };
    return options;
  };

  /**
   * Html to pdf
   *
   * @param text in html
   */
  private exportHtmlToPdf(text): Observable<any> {
    return this.http.post<any>(
      "https://api.satiurn.com/common/htmltopdf",
      { content: text },
      this.businessFPDF(true)
    );
  }

  private format(value: number): string {
    /* return formatCurrency(
       value,
       "us",
       getCurrencySymbol("USD", "wide"),
       "USD"
     );*/

    return (
      value.toFixed(2) +
      (this.selectedCurrency && this.selectedCurrency.symbol
        ? this.selectedCurrency.symbol
        : "")
    );
  }

  private isBusinessDataCompiled(): boolean {
    return (this.proposal != undefined && (this.proposal.business != "" || this.proposal.businessEmail != "" || this.proposal.businessName != "" || this.proposal.businessOffice != "" || this.proposal.businessPhone != "" || this.proposal.businessSurname != "" || this.proposal.businessVat != ""));
  }

  private isCustomerDataCompiled(): boolean {
    return (this.proposal != undefined && (this.proposal.customerPrincipal != "" || this.proposal.customerEmail != "" || this.proposal.customerName != "" || this.proposal.customerOffice != "" || this.proposal.customerPhone != "" || this.proposal.customerSurname != "" || this.proposal.customerVat != ""));
  }

  /**
   * From the proposal set the html for the pdf
   *
   * @returns string with the html
   */
  public proposalTopHtml(): string {
    console.log(this.f.date.value);
    this.saveProposalData();

    let html = "";

    if (this.isBusinessDataCompiled()) {
      html +=
        `<table class="mce-grid-table" style="font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; border-color: transparent; width: 100%;" border="0" data-mce-id="__mce">
          <tbody>
              <tr>
                ` +
        (this.logoBase64
          ? `
                <td class="mce-grid-cell" style="padding: 0px 20px; border: none; width: 19.0476%;"><img style="float: left;" src="` +
          this.logoBase64 +
          `" alt="LOGO" width="158" height="158" /></span></td>`
          : "") +
        `
                <td class="mce-grid-cell" style="width: 100%; border: none; padding-left: 20px !important; line-height: 22px;">
                    <p style="line-height: 30px;"><span style="color: #222b45; font-size: 14pt;"><strong>` +
        (this.proposal.business
          ? this.proposal.business
          : this.proposal.businessName + " " + this.proposal.businessSurname) +
        `</strong></span><br /></p><p>` +
        (this.proposal.businessOffice
          ? '<span style="color: #222b45; font-size: 10pt;">' +
          this.proposal.businessOffice +
          "</span><br />"
          : "") +
        `</strong></span>
                    ` +
        (this.proposal.businessVat
          ? '<span style="color: #222b45; font-size: 10pt;">VAT ' +
          this.proposal.businessVat +
          "</span><br />"
          : "") +
        `</strong></span>
                          ` +
        (this.proposal.businessEmail
          ? '<span style="color: #222b45; font-size: 10pt;">' +
          this.proposal.businessEmail +
          "</span><br />"
          : "") +
        (this.proposal.businessPhone
          ? '<span style="color: #222b45; font-size: 10pt;">' +
          this.proposal.businessPhone +
          "</span><br />"
          : "") +
        `</p>
                </td>
              </tr>
          </tbody>
        </table>`;
    }

    if (this.currentTemplate != proposalTemplate.priceList && this.isCustomerDataCompiled()) {
      html +=
        `<table class="mce-grid-table" style="border-color: transparent; width: 100%; table-layout: fixed;" border="0" data-mce-id="__mce">
      <tbody>
          <tr>
            <td class="mce-grid-cell" style="border: none; width: 60%;vertical-align: top; line-height: 22px;">
                <p><span style="color: #222b45; font-size: 12pt;"><strong>Proposal for ` +
        (this.proposal.customerPrincipal
          ? this.proposal.customerPrincipal
          : this.proposal.customerName + " " + this.proposal.customerSurname) +
        `</strong></span></p>` +
        (this.proposal.customerOffice
          ? `<span style="color: #222b45; font-size: 10pt;">` +
          this.proposal.customerOffice +
          `</span><br />`
          : "") +
        (this.proposal.customerVat
          ? `<span style="color: #222b45; font-size: 10pt;">VAT ` +
          this.proposal.customerVat +
          `</span><br />`
          : "") +
        (this.proposal.customerEmail
          ? `<span style="color: #222b45; font-size: 10pt;">` +
          this.proposal.customerEmail +
          `</span><br />`
          : "") +
        (this.proposal.customerPhone
          ? `<span style="color: #222b45; font-size: 10pt;">` +
          this.proposal.customerPhone +
          `</span><br />`
          : "") +
        `</td>
            <td class="mce-grid-cell" style="border: none; width: 40%;vertical-align: top;">
                <p>&nbsp;</p>
                <table class="mce-grid-table" style="border-color: transparent; width: 99.6389%; table-layout: fixed; height: 66px;" border="0" data-mce-id="__mce">
                  <tbody>
                      <tr style="height: 22px;">
                        <td style="border: none; height: 22px; width: 30.638%;"><span style="color: #222b45; font-size: 10pt;">Period</span></td>
                        <td style="border: none; height: 22px; text-align: right; width: 78.8728%;"><span style="color: #222b45; font-size: 10pt;">` +
        (this.proposal.date.start
          ? this.datepipe.transform(this.proposal.date.start, "mediumDate")
          : "-") +
        (this.proposal.date.end
          ? " - " +
          this.datepipe.transform(this.proposal.date.end, "mediumDate")
          : "") +
        `</span></td>
                      </tr>
                  </tbody>
                </table>
            </td>
          </tr>
      </tbody>
    </table>`;
    }

    console.log(this.proposal.date);

    return html;
  }

  public proposalBottomHtml(): string {
    let html = "";

    switch (this.currentTemplate) {
      case proposalTemplate.standard:
        html += this.getDetailsHtml();
        html += this.getRecapHtml();
        break;
      case proposalTemplate.contract:
        html += this.getContractHtml();
        break;
      case proposalTemplate.priceList:
        html += this.getPriceListHtml();
        break;
    }

    return html;
  }

  public getDetailsHtml(): string {
    let detailsRows = "";
    if (!this.proposal.details) {
      detailsRows = `<tr>
         <td style="width: 58.8021%; border-style: hidden; background-color: #ffffff;">&nbsp;</td>
         <td style="width: 7.81594%; border-style: hidden; background-color: #ffffff;">&nbsp;</td>
         <td style="width: 8.32725%; border-style: hidden; background-color: #ffffff;">&nbsp;</td>
         <td style="width: 8.32725%; border-style: hidden; background-color: #ffffff;">&nbsp;</td>
         <td style="width: 8.32725%; border-style: hidden; background-color: #ffffff;">&nbsp;</td>
         <td style="width: 8.32725%; border-style: hidden; background-color: #ffffff;">&nbsp;</td>
      </tr>
      <tr>
         <td style="width: 58.8021%; border-style: hidden; background-color: #f6f9fc;">&nbsp;</td>
         <td style="width: 7.81594%; border-style: hidden; background-color: #f6f9fc;">&nbsp;</td>
         <td style="width: 8.32725%; border-style: hidden; background-color: #f6f9fc;">&nbsp;</td>
         <td style="width: 8.32725%; border-style: hidden; background-color: #f6f9fc;">&nbsp;</td>
         <td style="width: 8.32725%; border-style: hidden; background-color: #f6f9fc;">&nbsp;</td>
         <td style="width: 8.32725%; border-style: hidden; background-color: #f6f9fc;">&nbsp;</td>
      </tr>`;
    } else {
      for (let i = 0; i < this.proposal.details.length; i++) {
        let detail = this.proposal.details[i];
        detailsRows +=
          `<tr style="height: 21px;">
         <td style="width: 58.8021%; height: 21px; border-style: hidden;"><span style="color: #222b45; font-size: 10pt;">` +
          detail.title +
          `</span></td>
         <td style="width: 7.81594%; height: 21px; border-style: hidden;"><span style="color: #222b45; font-size: 10pt;">` +
          detail.quantity +
          `</span></td>
         <td style="width: 8.32725%; height: 21px; border-style: hidden;"><span style="color: #222b45; font-size: 10pt;">` +
          this.format(detail.unitPrice) +
          `</span></td>
         <td style="width: 8.32725%; height: 21px; border-style: hidden;"><span style="color: #222b45; font-size: 10pt;">` +
          this.format(detail.discount) +
          `</span></td>
         <td style="width: 8.32725%; height: 21px; border-style: hidden;"><span style="color: #222b45; font-size: 10pt;">` +
          this.format((detail.unitPrice - detail.discount) * detail.quantity) +
          `</span></td>
         <td style="width: 8.32725%; height: 21px; border-style: hidden;"><span style="color: #222b45; font-size: 10pt;">` +
          detail.tax +
          `</span></td>
      </tr>`;
      }
    }

    const detailsHtml =
      `<p><span style="font-size: 12pt; color: #222b45;"><strong>` +
      (this.proposal.description ? "Description:" : "") +
      `</strong></span></p>
      <p><span style="background-color: #ffffff; font-family: 'Open Sans', Arial, sans-serif; font-size: 10pt; text-align: justify; color: #222b45; line-height: 22px;">` +
      (this.proposal.description ? this.proposal.description : "") +
      `</span></p>
      <p>&nbsp;</p>
      <p><span style="color: #222b45;"><strong>Details:</strong></span></p>
      <table class="satiurn-table" style="border-collapse: collapse; width: 100%; height: 41px;" border="1" data-mce-id="__mce">
       <tbody>
          <tr style="height: 20px;">
             <th style="width: 58.8021%; height: 20px; border: 2px solid #8f9bb3; text-align: left;"><span style="color: #222b45; font-size: 10pt;"><strong>Description</strong></span></th>
             <th style="width: 7.81594%; height: 20px; border: 2px solid #8f9bb3; text-align: left;"><span style="color: #222b45; font-size: 10pt;"><strong>Q.ty</strong></span></th>
             <th style="width: 8.32725%; height: 20px; border: 2px solid #8f9bb3; text-align: left;"><span style="color: #222b45; font-size: 10pt;"><strong>Price</strong></span></th>
             <th style="width: 8.32725%; height: 20px; border: 2px solid #8f9bb3; text-align: left;"><span style="color: #222b45; font-size: 10pt;"><strong>Discount</strong></span></th>
             <th style="width: 8.32725%; height: 20px; border: 2px solid #8f9bb3; text-align: left;"><span style="color: #222b45; font-size: 10pt;"><strong>Total</strong></span></th>
             <th style="width: 8.32725%; height: 20px; border: 2px solid #8f9bb3; text-align: left;"><span style="color: #222b45; font-size: 10pt;"><strong>Tax</strong></span></th>
          </tr>
          ` +
      detailsRows +
      `
       </tbody>
    </table>`;

    console.log(this.proposal.details, detailsHtml);
    return detailsHtml;
  }

  public getContractHtml(): string {
    let contractHtml = "";
    if (this.proposal.contractParagraphs) {
      for (let i = 0; i < this.proposal.contractParagraphs.length; i++) {
        let paragraph = this.proposal.contractParagraphs[i];
        contractHtml +=
          `<p><span style="font-size: 12pt; color: #222b45;"><strong>` +
          paragraph.title +
          `</strong></span></p>
          <p><span style="background-color: #ffffff; font-family: 'Open Sans', Arial, sans-serif; font-size: 10pt; text-align: justify; color: #222b45; line-height: 22px;">` +
          paragraph.description +
          `</span></p>
          <p>&nbsp;</p>`;
      }
    }
    return contractHtml;
  }

  public getPriceListHtml(): string {
    let priceListRows = "";
    if (!this.proposal.priceList) {
      priceListRows = `<tr>
         <td style="width: 40%; border-style: hidden; background-color: #ffffff;">&nbsp;</td>
         <td style="width: 50%; border-style: hidden; background-color: #ffffff;">&nbsp;</td>
         <td style="width: 10%; border-style: hidden; background-color: #ffffff;">&nbsp;</td>
      </tr>
      <tr>
         <td style="width: 40%; border-style: hidden; background-color: #f6f9fc;">&nbsp;</td>
         <td style="width: 50%; border-style: hidden; background-color: #f6f9fc;">&nbsp;</td>
         <td style="width: 10%; border-style: hidden; background-color: #f6f9fc;">&nbsp;</td>
      </tr>`;
    } else {
      for (let i = 0; i < this.proposal.priceList.length; i++) {
        let priceRow = this.proposal.priceList[i];
        priceListRows +=
          `<tr style="height: 21px;">
         <td style="width: 40%; height: 21px; border-style: hidden;"><span style="color: #222b45; font-size: 10pt;">` +
          priceRow.title +
          `</span></td>
         <td style="width: 50%; height: 21px; border-style: hidden;"><span style="color: #222b45; font-size: 10pt;">` +
          priceRow.description +
          `</span></td>
         <td style="width: 10%; height: 21px; border-style: hidden;"><span style="color: #222b45; font-size: 10pt;">` +
          this.format(priceRow.unitPrice) +
          `</span></td>
      </tr>`;
      }
    }

    const priceListHtml =
      `<table class="satiurn-table" style="border-collapse: collapse; width: 100%; height: 41px;" border="1" data-mce-id="__mce">
        <tbody>
            <tr style="height: 20px;">
              <th style="width: 40%; height: 20px; border: 2px solid #8f9bb3; text-align: left;"><span style="color: #222b45; font-size: 10pt;"><strong>Name</strong></span></th>
              <th style="width: 50%; height: 20px; border: 2px solid #8f9bb3; text-align: left;"><span style="color: #222b45; font-size: 10pt;"><strong>Description</strong></span></th>
              <th style="width: 10%; height: 20px; border: 2px solid #8f9bb3; text-align: left;"><span style="color: #222b45; font-size: 10pt;"><strong>Price</strong></span></th>
            </tr>
            ` +
      priceListRows +
      `
        </tbody>
      </table>`;

    return priceListHtml;
  }

  /**
   * From the details make the recap html
   *
   * @param details: detail[]
   * @returns html: string
   */
  private getRecapHtml(): string {
    let htmlResult = "";

    let detailsResult: documentDetailsTotals = {
      taxTotal: 0,
      proposalTotal: 0,
      total: 0,
      discountTotal: 0,
      taxable: 0,
    };

    if (this.proposal.details) {
      for (let z = 0; z < this.proposal.details.length; z++) {
        const dt: proposalDetailRow = this.proposal.details[z];
        detailsResult.proposalTotal += (dt.unitPrice - dt.discount) * dt.quantity;
        detailsResult.taxable += (dt.unitPrice - dt.discount) * dt.quantity;
        detailsResult.taxTotal += ((dt.unitPrice - dt.discount) * dt.quantity * dt.tax) / 100;
      }
    }
    detailsResult.total = detailsResult.taxable + detailsResult.taxTotal;

    htmlResult =
      `<table class="mce-grid-table" style="border-color: transparent; width: 100%; table-layout: fixed;" border="0" data-mce-id="__mce">
        <tbody>
            <tr>
              <td class="mce-grid-cell" style="border: none;" colspan="4">&nbsp;</td>
              <td class="mce-grid-cell" style="border: none;" colspan="2">
                  <table class="mce-grid-table" style="border-color: transparent; width: 100%; table-layout: fixed; height: 110px;" border="0" data-mce-id="__mce">
                    <tbody>
                        <tr style="height: 22px;">
                          <td class="mce-grid-cell" style="border: none; height: 22px;"><span style="color: #222b45; font-size: 10pt;"><strong>Net total:</strong></span></td>
                          <td class="mce-grid-cell" style="border: none; text-align: right; height: 22px;"><span style="color: #222b45;">` +
      this.format(detailsResult.taxable) +
      `</span></td>
                        </tr>
                        <tr style="height: 22px;">
                          <td style="border: none; height: 22px;"><span style="color: #222b45; font-size: 10pt;"><strong>Tax total:</strong></span></td>
                          <td style="border: none; text-align: right; height: 22px;"><span style="color: #222b45;">` +
      this.format(detailsResult.taxTotal) +
      `</span></td>
                        </tr>
                        <tr style="height: 22px;">
                          <td style="border: none; height: 22px;">&nbsp;</td>
                          <td style="border: none; text-align: right; height: 22px;">&nbsp;</td>
                        </tr>
                        <tr style="height: 22px;">
                          <td style="border: none; height: 22px;"><span style="color: #222b45; font-size: 10pt;"><strong>Total:</strong></span></td>
                          <td style="border: none; text-align: right; height: 22px;"><span style="color: #222b45;">` +
      this.format(detailsResult.total) +
      `</span></td>
                        </tr>
                    </tbody>
                  </table>
              </td>
            </tr>
        </tbody>
      </table>`;

    return htmlResult;
  }

  readonly currencies: currency[] = [
    { cc: "EUR", symbol: "\u20ac", name: "European Euro" },
    { cc: "USD", symbol: "US$", name: "United States dollar" },
    { cc: "GBP", symbol: "\u00a3", name: "British pound" },
    { cc: "JPY", symbol: "\u00a5", name: "Japanese yen" },
    { cc: "INR", symbol: "\u20B9", name: "Indian rupee" },
    { cc: "CNY", symbol: "\u00a5", name: "Chinese/Yuan renminbi" },
    { cc: "AED", symbol: "\u062f.\u0625;", name: "UAE dirham" },
    { cc: "AFN", symbol: "Afs", name: "Afghan afghani" },
    { cc: "ALL", symbol: "L", name: "Albanian lek" },
    { cc: "AMD", symbol: "AMD", name: "Armenian dram" },
    { cc: "ANG", symbol: "NA\u0192", name: "Netherlands Antillean gulden" },
    { cc: "AOA", symbol: "Kz", name: "Angolan kwanza" },
    { cc: "ARS", symbol: "$", name: "Argentine peso" },
    { cc: "AUD", symbol: "$", name: "Australian dollar" },
    { cc: "AWG", symbol: "\u0192", name: "Aruban florin" },
    { cc: "AZN", symbol: "AZN", name: "Azerbaijani manat" },
    {
      cc: "BAM",
      symbol: "KM",
      name: "Bosnia and Herzegovina konvertibilna marka",
    },
    { cc: "BBD", symbol: "Bds$", name: "Barbadian dollar" },
    { cc: "BDT", symbol: "\u09f3", name: "Bangladeshi taka" },
    { cc: "BGN", symbol: "BGN", name: "Bulgarian lev" },
    { cc: "BHD", symbol: ".\u062f.\u0628", name: "Bahraini dinar" },
    { cc: "BIF", symbol: "FBu", name: "Burundi franc" },
    { cc: "BMD", symbol: "BD$", name: "Bermudian dollar" },
    { cc: "BND", symbol: "B$", name: "Brunei dollar" },
    { cc: "BOB", symbol: "Bs.", name: "Bolivian boliviano" },
    { cc: "BRL", symbol: "R$", name: "Brazilian real" },
    { cc: "BSD", symbol: "B$", name: "Bahamian dollar" },
    { cc: "BTN", symbol: "Nu.", name: "Bhutanese ngultrum" },
    { cc: "BWP", symbol: "P", name: "Botswana pula" },
    { cc: "BYR", symbol: "Br", name: "Belarusian ruble" },
    { cc: "BZD", symbol: "BZ$", name: "Belize dollar" },
    { cc: "CAD", symbol: "$", name: "Canadian dollar" },
    { cc: "CDF", symbol: "F", name: "Congolese franc" },
    { cc: "CHF", symbol: "Fr.", name: "Swiss franc" },
    { cc: "CLP", symbol: "$", name: "Chilean peso" },
    { cc: "COP", symbol: "Col$", name: "Colombian peso" },
    { cc: "CRC", symbol: "\u20a1", name: "Costa Rican colon" },
    { cc: "CUC", symbol: "$", name: "Cuban peso" },
    { cc: "CVE", symbol: "Esc", name: "Cape Verdean escudo" },
    { cc: "CZK", symbol: "K\u010d", name: "Czech koruna" },
    { cc: "DJF", symbol: "Fdj", name: "Djiboutian franc" },
    { cc: "DKK", symbol: "Kr", name: "Danish krone" },
    { cc: "DOP", symbol: "RD$", name: "Dominican peso" },
    { cc: "DZD", symbol: "\u062f.\u062c", name: "Algerian dinar" },
    { cc: "EEK", symbol: "KR", name: "Estonian kroon" },
    { cc: "EGP", symbol: "\u00a3", name: "Egyptian pound" },
    { cc: "ERN", symbol: "Nfa", name: "Eritrean nakfa" },
    { cc: "ETB", symbol: "Br", name: "Ethiopian birr" },
    { cc: "FJD", symbol: "FJ$", name: "Fijian dollar" },
    { cc: "FKP", symbol: "\u00a3", name: "Falkland Islands pound" },
    { cc: "GEL", symbol: "GEL", name: "Georgian lari" },
    { cc: "GHS", symbol: "GH\u20b5", name: "Ghanaian cedi" },
    { cc: "GIP", symbol: "\u00a3", name: "Gibraltar pound" },
    { cc: "GMD", symbol: "D", name: "Gambian dalasi" },
    { cc: "GNF", symbol: "FG", name: "Guinean franc" },
    { cc: "GQE", symbol: "CFA", name: "Central African CFA franc" },
    { cc: "GTQ", symbol: "Q", name: "Guatemalan quetzal" },
    { cc: "GYD", symbol: "GY$", name: "Guyanese dollar" },
    { cc: "HKD", symbol: "HK$", name: "Hong Kong dollar" },
    { cc: "HNL", symbol: "L", name: "Honduran lempira" },
    { cc: "HRK", symbol: "kn", name: "Croatian kuna" },
    { cc: "HTG", symbol: "G", name: "Haitian gourde" },
    { cc: "HUF", symbol: "Ft", name: "Hungarian forint" },
    { cc: "IDR", symbol: "Rp", name: "Indonesian rupiah" },
    { cc: "ILS", symbol: "\u20aa", name: "Israeli new sheqel" },
    { cc: "IQD", symbol: "\u062f.\u0639", name: "Iraqi dinar" },
    { cc: "IRR", symbol: "IRR", name: "Iranian rial" },
    { cc: "ISK", symbol: "kr", name: "Icelandic kr\u00f3na" },
    { cc: "JMD", symbol: "J$", name: "Jamaican dollar" },
    { cc: "JOD", symbol: "JOD", name: "Jordanian dinar" },
    { cc: "KES", symbol: "KSh", name: "Kenyan shilling" },
    { cc: "KGS", symbol: "\u0441\u043e\u043c", name: "Kyrgyzstani som" },
    { cc: "KHR", symbol: "\u17db", name: "Cambodian riel" },
    { cc: "KMF", symbol: "KMF", name: "Comorian franc" },
    { cc: "KPW", symbol: "W", name: "North Korean won" },
    { cc: "KRW", symbol: "W", name: "South Korean won" },
    { cc: "KWD", symbol: "KWD", name: "Kuwaiti dinar" },
    { cc: "KYD", symbol: "KY$", name: "Cayman Islands dollar" },
    { cc: "KZT", symbol: "T", name: "Kazakhstani tenge" },
    { cc: "LAK", symbol: "KN", name: "Lao kip" },
    { cc: "LBP", symbol: "\u00a3", name: "Lebanese lira" },
    { cc: "LKR", symbol: "Rs", name: "Sri Lankan rupee" },
    { cc: "LRD", symbol: "L$", name: "Liberian dollar" },
    { cc: "LSL", symbol: "M", name: "Lesotho loti" },
    { cc: "LTL", symbol: "Lt", name: "Lithuanian litas" },
    { cc: "LVL", symbol: "Ls", name: "Latvian lats" },
    { cc: "LYD", symbol: "LD", name: "Libyan dinar" },
    { cc: "MAD", symbol: "MAD", name: "Moroccan dirham" },
    { cc: "MDL", symbol: "MDL", name: "Moldovan leu" },
    { cc: "MGA", symbol: "FMG", name: "Malagasy ariary" },
    { cc: "MKD", symbol: "MKD", name: "Macedonian denar" },
    { cc: "MMK", symbol: "K", name: "Myanma kyat" },
    { cc: "MNT", symbol: "\u20ae", name: "Mongolian tugrik" },
    { cc: "MOP", symbol: "P", name: "Macanese pataca" },
    { cc: "MRO", symbol: "UM", name: "Mauritanian ouguiya" },
    { cc: "MUR", symbol: "Rs", name: "Mauritian rupee" },
    { cc: "MVR", symbol: "Rf", name: "Maldivian rufiyaa" },
    { cc: "MWK", symbol: "MK", name: "Malawian kwacha" },
    { cc: "MXN", symbol: "$", name: "Mexican peso" },
    { cc: "MYR", symbol: "RM", name: "Malaysian ringgit" },
    { cc: "MZM", symbol: "MTn", name: "Mozambican metical" },
    { cc: "NAD", symbol: "N$", name: "Namibian dollar" },
    { cc: "NGN", symbol: "\u20a6", name: "Nigerian naira" },
    { cc: "NIO", symbol: "C$", name: "Nicaraguan c\u00f3rdoba" },
    { cc: "NOK", symbol: "kr", name: "Norwegian krone" },
    { cc: "NPR", symbol: "NRs", name: "Nepalese rupee" },
    { cc: "NZD", symbol: "NZ$", name: "New Zealand dollar" },
    { cc: "OMR", symbol: "OMR", name: "Omani rial" },
    { cc: "PAB", symbol: "B./", name: "Panamanian balboa" },
    { cc: "PEN", symbol: "S/.", name: "Peruvian nuevo sol" },
    { cc: "PGK", symbol: "K", name: "Papua New Guinean kina" },
    { cc: "PHP", symbol: "\u20b1", name: "Philippine peso" },
    { cc: "PKR", symbol: "Rs.", name: "Pakistani rupee" },
    { cc: "PLN", symbol: "z\u0142", name: "Polish zloty" },
    { cc: "PYG", symbol: "\u20b2", name: "Paraguayan guarani" },
    { cc: "QAR", symbol: "QR", name: "Qatari riyal" },
    { cc: "RON", symbol: "L", name: "Romanian leu" },
    { cc: "RSD", symbol: "din.", name: "Serbian dinar" },
    { cc: "RUB", symbol: "R", name: "Russian ruble" },
    { cc: "SAR", symbol: "SR", name: "Saudi riyal" },
    { cc: "SBD", symbol: "SI$", name: "Solomon Islands dollar" },
    { cc: "SCR", symbol: "SR", name: "Seychellois rupee" },
    { cc: "SDG", symbol: "SDG", name: "Sudanese pound" },
    { cc: "SEK", symbol: "kr", name: "Swedish krona" },
    { cc: "SGD", symbol: "S$", name: "Singapore dollar" },
    { cc: "SHP", symbol: "\u00a3", name: "Saint Helena pound" },
    { cc: "SLL", symbol: "Le", name: "Sierra Leonean leone" },
    { cc: "SOS", symbol: "Sh.", name: "Somali shilling" },
    { cc: "SRD", symbol: "$", name: "Surinamese dollar" },
    { cc: "SYP", symbol: "LS", name: "Syrian pound" },
    { cc: "SZL", symbol: "E", name: "Swazi lilangeni" },
    { cc: "THB", symbol: "\u0e3f", name: "Thai baht" },
    { cc: "TJS", symbol: "TJS", name: "Tajikistani somoni" },
    { cc: "TMT", symbol: "m", name: "Turkmen manat" },
    { cc: "TND", symbol: "DT", name: "Tunisian dinar" },
    { cc: "TRY", symbol: "TRY", name: "Turkish new lira" },
    { cc: "TTD", symbol: "TT$", name: "Trinidad and Tobago dollar" },
    { cc: "TWD", symbol: "NT$", name: "New Taiwan dollar" },
    { cc: "TZS", symbol: "TZS", name: "Tanzanian shilling" },
    { cc: "UAH", symbol: "UAH", name: "Ukrainian hryvnia" },
    { cc: "UGX", symbol: "USh", name: "Ugandan shilling" },
    { cc: "UYU", symbol: "$U", name: "Uruguayan peso" },
    { cc: "UZS", symbol: "UZS", name: "Uzbekistani som" },
    { cc: "VEB", symbol: "Bs", name: "Venezuelan bolivar" },
    { cc: "VND", symbol: "\u20ab", name: "Vietnamese dong" },
    { cc: "VUV", symbol: "VT", name: "Vanuatu vatu" },
    { cc: "WST", symbol: "WS$", name: "Samoan tala" },
    { cc: "XAF", symbol: "CFA", name: "Central African CFA franc" },
    { cc: "XCD", symbol: "EC$", name: "East Caribbean dollar" },
    { cc: "XDR", symbol: "SDR", name: "Special Drawing Rights" },
    { cc: "XOF", symbol: "CFA", name: "West African CFA franc" },
    { cc: "XPF", symbol: "F", name: "CFP franc" },
    { cc: "YER", symbol: "YER", name: "Yemeni rial" },
    { cc: "ZAR", symbol: "R", name: "South African rand" },
    { cc: "ZMK", symbol: "ZK", name: "Zambian kwacha" },
    { cc: "ZWR", symbol: "Z$", name: "Zimbabwean dollar" },
  ];
}

const fontImport: string =
  "<link href='https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap' rel='stylesheet'>";
