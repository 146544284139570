import { NgModule } from '@angular/core';

import { Routes, RouterModule } from '@angular/router';
import { ProposalGeneratorPageComponent } from './pages/proposal-generator-page/proposal-generator-page.component';
import { HomePageComponent } from './pages/home-page/home-page.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: 'home', component: HomePageComponent },
  { path: 'proposal-generator', component: ProposalGeneratorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
